






import { Component } from "vue-property-decorator";
import { ROUTE_EMPLOYEE_PORTAL_REQUESTS, ROUTE_USER_INFORMATION } from "@/router/routes";
import Vue from "vue";
import { AUTH_STORE_NAME, AuthStoreGetters } from "@/store/auth.store";
import User from "@/models/User.model";
import { namespace } from "vuex-class";
import { UserRoles } from "@/enum/UserRoles.enum";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
  }
})
export default class HomeView extends Vue {
  /**
   * Access to the currently logged-in user
   * @private
   */
  @AuthStore.Getter(AuthStoreGetters.CURRENT_USER)
  private user?: User;

  /**
   * redirects to the user information view for now
   */
  async created() {
    // navigates to the user information view if he isn't already on the page
    if(this.$route.name != ROUTE_USER_INFORMATION && this.$route.name != ROUTE_EMPLOYEE_PORTAL_REQUESTS) {
      if (this.user?.role === UserRoles.USER) return await this.$router.push({name: ROUTE_USER_INFORMATION});
      return await this.$router.push({name: ROUTE_EMPLOYEE_PORTAL_REQUESTS });
    }
  }
}
